<template>
  <div class="Properties">
    <NavBar />
    <div class="search-section container">
      <div class="head-section">
        <h2 class="header">Search properties for buy or rent</h2>
        <p class="sub-header">
          Discover the perfect property with K-Realty Rwanda in three easy steps
        </p>
      </div>
      <div class="work-items">
        <div class="work-item">
          <div class="content">
            <div class="left">
              <div class="left-top">
                <div class="input-group category">
                  <MySelect
                    class="my-select"
                    :options="[
                      {
                        id: null,
                        value: null,
                        name: 'All Types',
                      },
                      {
                        id: 1,
                        value: 'House',
                        name: 'House',
                        icon: 'fas fa-home',
                      },
                      {
                        id: 2,
                        value: 'Apartment',
                        name: 'Apartment',
                        icon: 'fas fa-building',
                      },
                      {
                        id: 3,
                        value: 'Land',
                        name: 'Land',
                        icon: 'fa-solid fa-map-location',
                      },
                    ]"
                    :icon="true"
                    :objects="true"
                    :labelKey="'name'"
                    :valueKey="'value'"
                    :color="'#555'"
                    :iconColor="'#1d2088'"
                    :iconKey="'icon'"
                    :value="searchData.category"
                    @input="
                      (value) => {
                        searchData.category = value;
                      }
                    "
                  />
                </div>
                <div class="input-group combo">
                  <v-combobox
                    v-model="searchData.activelocation"
                    :items="[
                      { name: 'All Locations', location_id: '' },
                      ...locations,
                    ]"
                    :itemTitle="(item) => item.name"
                    :itemValue="(item) => item.location_id"
                    density="compact"
                    class="input v-combo"
                    variant="undeline"
                    :placeholder="'Search Locations'"
                  >
                  </v-combobox>
                </div>
                <div class="input-group combo">
                  <MySelect
                    class="my-select"
                    :options="[
                      { name: 'All Conditions', status: '' },
                      { name: 'Furnished', status: 'Furnished' },
                      { name: 'Unfurnished', status: 'Unfurnished' },
                    ]"
                    :icon="true"
                    :objects="true"
                    :labelKey="'name'"
                    :valueKey="'status'"
                    :color="'#555'"
                    :iconColor="'#1d2088'"
                    :iconKey="'icon'"
                    :value="searchData.status"
                    @input="
                      (value) => {
                        searchData.status = value;
                      }
                    "
                  />
                </div>
                <div class="input-group">
                  <MySelect
                    class="my-select"
                    style="z-index: 3"
                    :options="[
                      {
                        id: null,
                        name: 'All Statuses',
                      },
                      {
                        id: 'Rent',
                        name: 'Rent',
                        icon: 'fa-solid fa-house-user',
                      },
                      {
                        id: 'Sale',
                        name: 'Sale',
                        icon: 'fa-solid fa-house-circle-check',
                      },
                    ]"
                    :icon="true"
                    :objects="true"
                    :labelKey="'name'"
                    :valueKey="'id'"
                    :color="'#555'"
                    :iconColor="'#1d2088'"
                    :iconKey="'icon'"
                    :value="searchData.type"
                    @input="
                      (value) => {
                        searchData.type = value;
                      }
                    "
                  />
                </div>
              </div>
              <div class="left-bottom">
                <div class="input-group">
                  <MySelect
                    class="my-select"
                    style="z-index: 2"
                    :options="[
                      {
                        id: null,
                        name: 'Currency',
                        value: null,
                      },
                      {
                        id: 1,
                        name: 'USD',
                        value: 'USD',
                        icon: 'fa-solid fa-dollar-sign',
                      },
                      {
                        id: 2,
                        name: 'RWF',
                        value: 'RWF',
                        icon: 'fa-solid fa-money-bill',
                      },
                    ]"
                    :icon="true"
                    :objects="true"
                    :labelKey="'name'"
                    :valueKey="'value'"
                    :color="'#555'"
                    :iconColor="'#1d2088'"
                    :iconKey="'icon'"
                    :value="searchData.currency"
                    @input="
                      (value) => {
                        searchData.currency = value;
                      }
                    "
                  />
                </div>
                <div class="input-group no-bg price-range">
                  <label for="" class="price-label">Price Range</label>
                  <v-range-slider
                    v-model="searchData.pricevalue1"
                    thumb-label="always"
                    style="width: 100%"
                    :max="5000000"
                    step="10000"
                    class="custom-slider"
                    color="#1d2088"
                    v-if="graph === 1"
                  >
                    <template v-slot:thumb-label="value">
                      {{ formatNumberWithCommas(value.modelValue) }}&nbsp;RWF
                    </template>
                  </v-range-slider>
                  <v-range-slider
                    v-model="searchData.pricevalue2"
                    thumb-label="always"
                    style="width: 100%"
                    :max="500000000"
                    step="10000"
                    class="custom-slider"
                    color="#1d2088"
                    v-if="graph === 2"
                  >
                    <template v-slot:thumb-label="value">
                      {{ formatNumberWithCommas(value.modelValue) }}&nbsp;RWF
                    </template>
                  </v-range-slider>
                  <v-range-slider
                    v-model="searchData.pricevalue3"
                    thumb-label="always"
                    style="width: 100%"
                    :max="5000"
                    step="100"
                    class="custom-slider"
                    color="#1d2088"
                    v-if="graph === 3"
                  >
                    <template v-slot:thumb-label="value">
                      {{ formatNumberWithCommas(value.modelValue) }}&nbsp;USD
                    </template></v-range-slider
                  >
                  <v-range-slider
                    v-model="searchData.pricevalue4"
                    thumb-label="always"
                    style="width: 100%"
                    :max="500000"
                    step="10000"
                    class="custom-slider"
                    color="#1d2088"
                    v-if="graph === 4"
                  >
                    <template v-slot:thumb-label="value">
                      {{ formatNumberWithCommas(value.modelValue) }}&nbsp;USD
                    </template>
                  </v-range-slider>
                  <v-range-slider
                    v-model="searchData.pricevalue5"
                    thumb-label="always"
                    style="width: 100%"
                    :max="500000000"
                    step="1000000"
                    class="custom-slider"
                    color="#1d2088"
                    v-if="graph === 5"
                  >
                    <template v-slot:thumb-label="value">
                      {{ formatNumberWithCommas(value.modelValue) }}&nbsp;RWF
                    </template>
                  </v-range-slider>
                  <v-range-slider
                    v-model="searchData.pricevalue6"
                    thumb-label="always"
                    style="width: 100%"
                    :max="500000"
                    step="1000"
                    class="custom-slider"
                    color="#1d2088"
                    v-if="graph === 6"
                  >
                    <template v-slot:thumb-label="value">
                      {{ formatNumberWithCommas(value.modelValue) }}&nbsp;USD
                    </template>
                  </v-range-slider>
                </div>
                <div class="input-group" style="padding: 0">
                  <a-input-number
                    id="inputNumber"
                    v-model:value="searchData.rooms"
                    :min="1"
                    :max="30"
                    :size="'large'"
                    style="
                      width: 100%;
                      height: 100%;
                      font-size: 16px;
                      background: #f0f0f0;
                      border: none;
                    "
                    placeholder="Any"
                    v-if="
                      searchData.category === 'House' ||
                      searchData.category === 'Apartment'
                    "
                  />
                  <select name="" id="" v-else>
                    <option value="" style="text-align: center">-</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="right-2">
              <div class="top-right">
                <h2>{{ allItemsCount }}</h2>
                <p>Results</p>
              </div>
              <div class="bottom-right">
                <button class="bp cw my-btn glow wd" @click="getItems()">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="properties.length">
      <div class="filtered-area">
        <div class="category-list">
          <div class="tab-header">
            <h2>Properties</h2>
          </div>
        </div>
        <div class="items-listing" v-if="properties.length">
          <PropertyCard
            v-for="item in properties"
            :key="item.property_id"
            :item="item"
          />
        </div>
      </div>
      <div class="pagination">
        <button
          :class="{ disabled: currentPage === 1 }"
          @click="$previousPage()"
        >
          <label>Previous</label>
        </button>
        <p v-if="currentPage > 3">...</p>
        <button class="numbers" v-for="number in pagesNumber" :key="number">
          <label
            :class="{ active: currentPage === number }"
            for=""
            v-if="
              (number == 1 && currentPage <= 2) ||
              (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
              (number >= currentPage - 2 && number <= currentPage + 2)
            "
            @click="$changePage(number)"
          >
            {{ number }}
          </label>
        </button>
        <p v-if="currentPage <= pagesNumber - 3">...</p>
        <button
          :class="{ disabled: currentPage === pagesNumber.length }"
          @click="$nextPage()"
        >
          <label>Next</label>
        </button>
      </div>
    </div>
    <div class="container" v-else>
      <div class="no-items">
        <h2>No properties found, please try a different search</h2>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  components: {},
  data() {
    return {
      locations: [],
      statuses: ["For Sale", "For Rent"],
      allCategories: [],
      allItems: [],
      filteredItems: [],
      selectedCategory: "all",
      currentIndex: 0,
      searchData: {
        pricevalue1: [0, 5000000],
        pricevalue2: [0, 500000000],
        pricevalue3: [0, 5000],
        pricevalue4: [0, 500000],
        pricevalue5: [0, 500000000],
        pricevalue6: [0, 500000],
        maxAmount: 5000000,
        location: null,
        category: null,
        status: null,
        currency: null,
        rooms: null,
        activelocation: null,
        type: null,
      },
      allItemsCount: 0,
      itemsPerpage: 24,
      pagesNumber: 0,
      currentPage: 1,
      graph: 1,
      properties: [],
      prices: {
        start: 0,
        end: 0,
      },
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      if (this.graph === 1) {
        this.prices.start = this.searchData.pricevalue1[0];
        this.prices.end = this.searchData.pricevalue1[1];
      }
      if (this.graph === 2) {
        this.prices.start = this.searchData.pricevalue2[0];
        this.prices.end = this.searchData.pricevalue2[1];
      }
      if (this.graph === 3) {
        this.prices.start = this.searchData.pricevalue3[0];
        this.prices.end = this.searchData.pricevalue3[1];
      }
      if (this.graph === 4) {
        this.prices.start = this.searchData.pricevalue4[0];
        this.prices.end = this.searchData.pricevalue4[1];
      }
      if (this.graph === 5) {
        this.prices.start = this.searchData.pricevalue5[0];
        this.prices.end = this.searchData.pricevalue5[1];
      }
      if (this.graph === 6) {
        this.prices.start = this.searchData.pricevalue6[0];
        this.prices.end = this.searchData.pricevalue6[1];
      }

      if (
        this.searchData.activelocation &&
        this.searchData.activelocation != null
      ) {
        this.searchData.location = this.searchData.activelocation.location_id;
      }
      const payload = { ...this.searchData };
      if (!payload.activelocation) {
        payload.location_id = null;
      } else {
        payload.location_id = payload.activelocation.location_id;
      }
      this.$store
        .dispatch("GET_FILTERED_PROPERTIES", {
          token: this.$getAdminToken(),
          data: {
            paginate: 24,
            page: this.currentPage,
            location_id: payload.location_id,
            category: payload.category,
            status: payload.status,
            currency: payload.currency,
            rooms: payload.rooms,
            price: this.prices,
            type: payload.type,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              if (res.data.properties) {
                this.properties = res.data.properties.data;
              }
              this.allItemsCount = res.data.properties.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
            } else {
              toast.error(res.data.message);
            }
          }
          this.$stopLoader();
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    changePage(page) {
      this.currentPage = page;
    },
    checkRanges() {
      if (this.searchData.currency == "RWF" && this.searchData.type == null) {
        this.graph = 1;
      }
      if (this.searchData.currency == "RWF" && this.searchData.type == "Rent") {
        this.graph = 1;
      }

      if (this.searchData.currency == "RWF" && this.searchData.type == "Sale") {
        this.graph = 2;
      }

      if (this.searchData.currency == "USD" && this.searchData.type == null) {
        this.graph = 3;
      }

      if (this.searchData.currency == "USD" && this.searchData.type == "Rent") {
        this.graph = 3;
      }

      if (this.searchData.currency == "USD" && this.searchData.type == "Sale") {
        this.graph = 4;
      }
    },
    formatNumberWithCommas(value) {
      if (value === undefined) {
        value = 0; // Or any other default value you want
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getLocations() {
      this.$startLoader();
      this.$store
        .dispatch("GET_LOCATIONS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 100,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.locations = res.data.locations.data;
              if (this.$route.query.location) {
                this.searchData.location = this.$route.query.location;
                let selectedLocation = this.locations.find(
                  (location) =>
                    location.location_id == this.$route.query.location
                );
                this.searchData.activelocation = selectedLocation;
                this.getItems();
              } else {
                this.getItems();
              }
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch((err) => {
          this.$stopLoader();
          toast.error("Something went wrong, please try again later.");
          console.log(err);
        });
    },
  },
  mounted() {
    this.getLocations();
    if (this.$route.query.page) {
      this.currentPage = parseInt(this.$route.query.page);
    }
    if (
      this.$route.query.location_id ||
      this.$route.query.category ||
      this.$route.query.type ||
      this.$route.query.status ||
      this.$route.query.rooms
    ) {
      if (this.$route.query.category) {
        this.searchData.category = this.$route.query.category;
      }
      if (this.$route.query.type) {
        this.searchData.type = this.$route.query.type;
      }
      if (this.$route.query.status) {
        this.searchData.status = this.$route.query.status;
      }
      if (this.$route.query.rooms) {
        this.searchData.rooms = parseInt(this.$route.query.rooms);
      }
    }
  },
  computed: {
    categoryNames() {
      return this.allCategories.map((category) => {
        return category.name;
      });
    },
  },
  watch: {
    "searchData.currency": function () {
      this.checkRanges();
      this.searchData.currency
        ? this.$router.push({
            query: { ...this.$route.query, currency: this.searchData.currency },
          })
        : this.$router.push({
            query: { ...this.$route.query, currency: "" },
          });
    },
    "searchData.status": function () {
      this.checkRanges();
      this.searchData.status
        ? this.$router.push({
            query: { ...this.$route.query, status: this.searchData.status },
          })
        : this.$router.push({
            query: { ...this.$route.query, status: "" },
          });
    },
    "searchData.category": function () {
      this.checkRanges();
      this.searchData.category
        ? this.$router.push({
            query: { ...this.$route.query, category: this.searchData.category },
          })
        : this.$router.push({
            query: { ...this.$route.query, category: "" },
          });
    },
    "searchData.rooms": function () {
      this.searchData.rooms
        ? this.$router.push({
            query: { ...this.$route.query, rooms: this.searchData.rooms },
          })
        : this.$router.push({
            query: { ...this.$route.query, rooms: "" },
          });
    },
    "searchData.type": function () {
      this.checkRanges();
      this.searchData.type
        ? this.$router.push({
            query: { ...this.$route.query, type: this.searchData.type },
          })
        : this.$router.push({
            query: { ...this.$route.query, type: "" },
          });
    },
    "searchData.activelocation": function () {
      this.searchData.activelocation
        ? this.$router.push({
            query: {
              ...this.$route.query,
              location: this.searchData.activelocation.location_id,
            },
          })
        : this.$router.push({
            query: { ...this.$route.query, location: "" },
          });
    },
    currentPage: function () {
      if (this.currentPage) {
        this.$router.push({
          query: { ...this.$route.query, page: this.currentPage },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.6.1/css/font-awesome.min.css");
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
select.fas {
  font-family: "FontAwesome", "Second Font name";
  font-size: 1rem;
  label {
    font-family: "Poppins", sans-serif !important;
  }
}
.Properties {
  .search-section {
    margin-top: 105px;
    background: #e8ecfd;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    .head-section {
      text-align: center;
      .header {
        font-size: 2rem;
        margin-bottom: 20px;
      }
      .sub-header {
        font-size: 1.2rem;
        color: $secondary_color;
      }
    }
    .work-items {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      column-gap: 2rem;
      margin-bottom: 4rem;
      width: 100%;
      .work-item {
        border-radius: 20px;
        background: var(--white, #fff);
        box-shadow: 0px 0px 30px 0px #010b3544;
        padding: 2rem 2rem;
        width: 100%;
        .content {
          display: flex;
          column-gap: 2rem;
          @media (max-width: 1000px) {
            flex-direction: column;
          }
          .icon {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 50%;
            margin-left: 10px;
            position: absolute;
            i,
            svg {
              height: 60%;
              width: 60%;
              color: #fff;
            }
          }
          .input-group {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 0;
            border: 1px solid #e0e0e0;
            background: #f0f0f0;
            border-radius: 10px;
            width: 100%;
            position: relative;
            height: 42px;
            &.no-bg {
              background: none;
              border: none;
              width: 98%;
            }
            select,
            input {
              padding: 8px 20px;
              border: none;
              font-size: 1rem;
              color: #555;
              background: none;
              width: 100%;
              &:focus {
                outline: none;
              }
            }
            .price-label {
              margin-bottom: 2rem;
            }
            &.icon-group {
              display: flex;
              select {
                padding-left: 45px !important;
              }
            }
            .my-select {
              width: 100%;
            }
            &.category {
              @media (max-width: 900px) {
                margin-top: 3rem;
              }
            }
          }

          .left {
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 1rem;
            padding-bottom: 2rem;
            @media (max-width: 1000px) {
              width: 100%;
            }
            .left-top {
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              gap: 2rem;
              @media (max-width: 1000px) {
                grid-template-columns: repeat(2, 1fr);
              }
              @media (max-width: 600px) {
                grid-template-columns: repeat(1, 1fr);
              }
            }
            .left-bottom {
              display: grid;
              grid-template-columns: 1fr 4fr 1fr;
              gap: 2rem;
              @media (max-width: 1300px) {
                grid-template-columns: 1fr 3fr 1fr;
              }
              @media (max-width: 1000px) {
                grid-template-columns: repeat(1, 1fr);
              }
              .price-range {
                width: 95%;
                @media (max-width: 1000px) {
                  width: 95%;
                  margin-bottom: 1rem;
                }
              }
            }
          }
          .right-2 {
            width: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 1rem;
            border-left: 1px solid #e0e0e0;
            padding: 0 2rem;
            padding-bottom: 2rem;
            @media (max-width: 1000px) {
              width: 100%;
              border-left: 0;
            }
            .top-right {
              text-align: center;
              h2 {
              }
              p {
                color: #969696;
                font-size: 1.1rem;
                text-align: center;
                font-weight: 400;
              }
            }
            button {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
  .filtered-area {
    margin-bottom: 3rem;
  }
  .no-items {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
    h2 {
      font-size: 2rem;
      color: #969696;
      text-align: center;
    }
  }
}
</style>
